'use strict';

angular.module('webPartnerPortalApp')
  .controller('PasswordModalCtrl', ['user', '$http', '$modalInstance', function(user, $http, $modalInstance) {
    var vm = this;
    vm.user = user;
    vm.password = {};
    vm.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
    vm.submit = function () {
      $http({
        method: 'PUT',
        url: 'api/user/password',
        data: {
          portal_user_id: user.portal_user_id,
          password: vm.password.password
        }
      }).then(function(){
        $modalInstance.close();
      }, function(err){
        vm.error = err.data.body.message;
      });
    };
  }]);
